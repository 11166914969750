



























































































import { Component, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import uniqid from 'uniqid';
import draggable from 'vuedraggable';

  @Component({
    components: {
      draggable,
    },
  })
export default class TasqsListSortPopup extends mixins() {
@Prop({ type: Array, default: () => [] }) checklistData!: any;

@Prop({ type: Boolean, default: false }) hideAddNewItem!: boolean;

@Prop({ type: Boolean, default: false }) showCheckbox!: boolean;

@Prop({ type: Boolean, default: false }) hideAddRemoveItem!: boolean;

@Prop({ type: Boolean, default: false }) hideItemBackground!: boolean;

checklist: any = []

  newItemText = ''

  created() {
    this.checklist = [...this.checklistData];
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  addItemToList() {
    if (!this.newItemText) {
      return;
    }
    this.checklist.push({
      id: uniqid(), title: this.newItemText, checked: false, isHovering: false,
    });
    this.newItemText = '';
    this.$emit('update-checklist', this.checklist);
  }

  removeItemFromList(item) {
    this.checklist = this.checklist.filter((step) => item.id !== step.id);
    this.$emit('update-checklist', this.checklist);
  }
}

